import { Box, Heading, Text, Stack, Link, Image } from "@chakra-ui/react"
import { Link as GL } from "gatsby"
import { graphql } from "gatsby"
import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LineIcon,
} from "react-share"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ThanksPage: React.FC<any> = ({ data }) => {
  return (
    <Layout>
      <Box pb={24} pt={36} px={8}>
        <Seo
          title="お問い合わせありがとうございます"
          description="お問い合わせありがとうございます"
          image={data.allContentfulSiteImages.edges[0].node.image.url}
        />
        {data.allContentfulSiteImages.edges.map(({ node: siteImage }: any) => (
          <Stack align="center" justify={`center`}>
            <Image
              src={siteImage.image.url}
              alt={`thanks`}
              boxSize={"350px"}
              objectFit={`cover`}
            />
          </Stack>
        ))}
        <Heading
          as={`h2`}
          mb={10}
          fontWeight="bold"
          fontSize={18}
          textAlign="center"
        >
          お問い合わせありがとうございます。 入力が完了いたしました。
        </Heading>
        <Text
          textAlign="center"
          lineHeight={{ base: 2.4, lg: 2 }}
          p={{ base: 4, lg: 0 }}
          fontSize={{ base: 14, lg: 18 }}
        >
          通常2~3営業日以内に担当者よりご連絡させていただきます。
          <Box as={`br`} />
          メール送信ができない場合やお急ぎの方は、お電話にてお問い合わせください。
          <Box as={`br`} />
          <Box as={`br`} />
          公式SNSをフォローいただくと
          <Box as={`br`} />
          新着物件の情報をイチ早くお届けいたします。
          <Stack
            direction="row"
            mt={12}
            spacing={4}
            align="center"
            justify={`center`}
          >
            <Link as={GL} to={`https://www.facebook.com/OfficialSaifarm/`}>
              <FacebookIcon size={40} round />
            </Link>
            <Link as={GL} to={`https://twitter.com/OfficialSaifarm/`}>
              <TwitterIcon size={40} round />
            </Link>
            {/* <Link as={GL} to={`/line-friend`}>
              <LineIcon size={40} round />
            </Link> */}
          </Stack>
        </Text>
      </Box>
    </Layout>
  )
}

export default ThanksPage

export const query = graphql`
  {
    allContentfulSiteImages(filter: { location: { eq: "thanks" } }, limit: 1) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
  }
`
